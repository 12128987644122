<template>
  <div style="margin-top: 30px;">
    <div style="display: flex; align-items: center;  border-bottom: 1px solid #E4E4E4;padding-bottom:16px;">
      <label style="margin: 0; margin-right: 15px">Activo: </label>
      <CSwitch
        variant="opposite"
        color="dark"
        :checked.sync="company_news_app_data.active"
        shape="pill"
      />
    </div>
    
    <div v-if="company_news_app_data.active">
      <div style="max-width: 900px; margin-top:16px;">
        <div style=" border-bottom: 1px solid #E4E4E4; padding-bottom: 24px;">
        <h3>Sección Noticias App</h3>
        <CRow>
          <CCol sm="12">
            <CInput label="Título" v-model="company_news_app_data.title" />
          </CCol>
          <CCol sm="12">
            <CTextarea
              label="Descripción"
              v-model="company_news_app_data.description"
            />
          </CCol>

          <CCol sm="12">
            <CRow>
              <CCol sm="6" md="4">
                <label class="label-img">Icono Principal: </label>
                <div
                  class="input-group file-input-group"
                  data-controller="file-input2"
                >
                  <input
                    :value="fileNameMain_icon"
                    style="background-color: #fff"
                    class="form-control"
                    type="text"
                    placeholder="Selecionar archivo"
                    readonly
                    data-target="file-input3.value"
                  />
                  <input
                    @change="onChangeMain_icon"
                    type="file"
                    class="form-control"
                    id="customFile3"
                    data-target="file-input3.input"
                    data-action="file-input3#display"
                  />
                  <div class="input-group-append">
                    <label
                      style="background-color: #fff"
                      class="btn btn-secondary mb-0"
                      for="customFile3"
                      ><img
                        style="width: 18px"
                        src="../../assets/icons/svg/clip.svg"
                        alt=""
                    /></label>
                  </div>
                </div>
                <img
                  v-if="previewMain_icon"
                  class="img-preview"
                  :src="previewMain_icon"
                  alt="preview"
                />
              </CCol>
              <CCol sm="6" md="4">
                <label class="label-img">Orden: </label>
                <CInput
                  type="number"
                  horizontal
                  v-model="company_news_app_data.order"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <button class="saveBtn" @click="saveConfigOptions">Guardar</button>
        <div class="alert" style="display:inline-block; margin-bottom:6px;">
          <CAlert v-if="alertSuccess1" color="success">Configuración actualizada</CAlert>
        </div>
        <div class="alert" style="display: inline-block; margin-bottom:6px;">
          <CAlert v-if="alertError1" color="red">Error al acutalizar</CAlert>
        </div>
      </div>
      <CSelect
      placeholder="Selecciona una opción"
        style="width: 200px; padding-top: 24px"
        label="Locales o Externas:"
        :options="[
          { value: true, label: 'Externas' },
          { value: false, label: 'Locales' },
        ]"
        v-model="company_news_config.active"
      />


      <!-- NOTICIAS LOCALES -->
      <div v-if="!company_news_config.active" style="margin-top: 12px;">
        <div style="display:flex; justify-content:flex-end;">
        <button class="saveBtn" @click="() => (createModal = !createModal)">
          Crear nueva
        </button>
        </div>

        <!--     Tabla Noticias -->
        <CDataTable
          :fields="fields"
          :items="news"
          hover
          sorter
          style=" margin-top: 24px"
          :items-per-page="limit"
        >
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                class="mr-2"
                color="danger"
                variant="outline"
                square
                size="sm"
                @click="setCurrentItem(item, 'edit')"
              >
                <img
                  style="width: 1rem"
                  src="../../assets/icons/svg/edit.svg"
                  alt=""
                />
              </CButton>
              <CButton
                color="red"
                variant="outline"
                square
                size="sm"
                @click="setCurrentItem(item, 'delete')"
              >
                <img
                  style="width: 1rem"
                  src="../../assets/icons/svg/delete.svg"
                  alt=""
                />
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="start"
          :pages="totalPages"
          size="md"
          align="left"
        />
        <!--     Modal crear -->
        <CModal
          style="width: 40% !important"
          class="modal-sm"
          title="Crear Noticia"
          color="warning"
          :show.sync="createModal"
          size="sm"
        >
          <CRow>
            <CCol sm="12">
              <CInput label="Título" v-model="newNew.title" />
            </CCol>
            <CCol sm="12">
              <CInput label="URL" v-model="newNew.url" />
            </CCol>

            <CCol sm="12">
              <CRow>
                <CCol sm="6" md="4">
                  <label class="label-img">Imagen: </label>
                  <div
                    class="input-group file-input-group"
                    data-controller="file-input2"
                  >
                    <input
                      :value="fileNameImageCreate"
                      style="background-color: #fff"
                      class="form-control"
                      type="text"
                      placeholder="Selecionar archivo"
                      readonly
                      data-target="file-input2.value"
                    />
                    <input
                      @change="onChangeImageCreate"
                      type="file"
                      class="form-control"
                      id="customFile2"
                      data-target="file-input2.input"
                      data-action="file-input2#display"
                    />
                    <div class="input-group-append">
                      <label
                        style="background-color: #fff"
                        class="btn btn-secondary mb-0"
                        for="customFile2"
                        ><img
                          style="width: 18px"
                          src="../../assets/icons/svg/clip.svg"
                          alt=""
                      /></label>
                    </div>
                  </div>
                  <img
                    v-if="previewImageCreate"
                    class="img-preview"
                    :src="previewImageCreate"
                    alt="preview"
                  />
                </CCol>
                <CCol sm="6" md="4">
                  <label class="label-img">Fecha: </label>
                  <CInput type="date" horizontal v-model="newNew.date" />
                </CCol>
                <CCol sm="6" md="4">
                  <div>
                    <label class="label-img">Notificaciones: </label>
                  </div>
                  <CSwitch
                    variant="opposite"
                    color="dark"
                    :checked.sync="newNew.notifications"
                    shape="pill"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="12">
              <wysiwyg v-model="newNew.body" />
            </CCol>
          </CRow>
          <div slot="footer" class="w-100">
            <button
              class="mr-2"
              color="danger"
              square
              :disabled="
                !newNew.title ||
                !newNew.url ||
                !newNew.image ||
                !newNew.date ||
                !newNew.body
              "
              size="sm"
              @click="createNew()"
            >
              Guardar
            </button>
            <CButton
              color="red"
              square
              size="sm"
              @click="() => (createModal = !createModal)"
            >
              Cancelar
            </CButton>
          </div>
        </CModal>

        <!--     Modal Eliminar -->
        <CModal
          title="Eliminar Noticia"
          color="warning"
          :show.sync="deleteModal"
          size="sm"
        >
          ¿Seguro que quieres eliminar la noticia
          <span style="font-weight: 800">{{ currentNew.title }}</span
          >?
          <div slot="footer" class="w-100">
            <CButton
              class="mr-2"
              color="red"
              variant="outline"
              square
              @click="deleteNew()"
            >
              Eliminar
            </CButton>
            <CButton
              color="secondary"
              variant="outline"
              square
              @click="() => (deleteModal = !deleteModal)"
            >
              Cancelar
            </CButton>
          </div>
        </CModal>

        <!--     Modal editar -->
        <CModal
          class="modal-sm"
          title="Editar noticia"
          color="warning"
          :show.sync="editModal"
          size="sm"
        >
          <CRow>
            <CCol sm="12">
              <CInput label="Título" v-model="currentNew.title" />
            </CCol>
            <CCol sm="12">
              <CInput label="URL" v-model="currentNew.url" />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="6" md="4">
                  <label class="label-img">Imagen: </label>
                  <div
                    class="input-group file-input-group"
                    data-controller="file-input5"
                  >
                    <input
                      :value="fileNameImageUpdate"
                      style="background-color: #fff"
                      class="form-control"
                      type="text"
                      placeholder="Selecionar archivo"
                      readonly
                      data-target="file-input5.value"
                    />
                    <input
                      @change="onChangeImageUpdate"
                      type="file"
                      class="form-control"
                      id="customFile5"
                      data-target="file-input5.input"
                      data-action="file-input5#display"
                    />
                    <div class="input-group-append">
                      <label
                        style="background-color: #fff"
                        class="btn btn-secondary mb-0"
                        for="customFile5"
                        ><img
                          style="width: 18px"
                          src="../../assets/icons/svg/clip.svg"
                          alt=""
                      /></label>
                    </div>
                  </div>
                  <img
                    v-if="previewImageUpdate"
                    class="img-preview"
                    :src="previewImageUpdate"
                    alt="preview"
                  />
                </CCol>
                <CCol sm="6" md="4">
                  <label class="label-img">Fecha: </label>
                  <CInput type="date" horizontal v-model="currentNew.date" />
                </CCol>
<!--                 <CCol sm="6" md="4">
                  <div>
                    <label class="label-img">Notificaciones: </label>
                  </div>
                  <CSwitch
                    variant="opposite"
                    color="dark"
                    :checked.sync="currentNew.notifications"
                    shape="pill"
                  />
                </CCol> -->
                <CCol sm="12">
                  <wysiwyg v-model="currentNew.body" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <div slot="footer" class="w-100">
            <CButton
              class="mr-2"
              color="danger"
              square
              size="sm"
              :disabled="
                !currentNew.title ||
                !currentNew.url ||
                !currentNew.image ||
                !currentNew.date ||
                !currentNew.body
              "
              @click="updateNew()"
            >
              Guardar
            </CButton>
            <CButton
              color="red"
              square
              size="sm"
              @click="() => (editModal = !editModal)"
            >
              Cancelar
            </CButton>
          </div>
        </CModal>
      </div>


      <div v-else>
        <h3 style="margin-top:12px;">Configuración Externa</h3>
        <CRow>
          <CCol sm="6">
            <CInput
              label="URL petición"
              v-model="company_news_config.news_request_url"
            />
            <small v-if="!company_news_config.news_request_url">*Campo requerido</small>
          </CCol>
          <CCol sm="6">
            <CSelect
              style="width: 200px; margin-top: 12px"
              label="Tipo de petición:"
              :options="[
                { value: 'get', label: 'GET' },
                { value: 'post', label: 'POST' },
              ]"
              v-model="company_news_config.request_type"
            />
            <small v-if="!company_news_config.request_type">*Campo requerido</small>
          </CCol>

          <CCol sm="12">
            <CRow>
              <CCol sm="6" md="4">
                <CInput
                  label="URL Base"
                  v-model="company_news_config.base_web_url"
                />
              </CCol>
              <CCol sm="6" md="4">
                <CInput
                  label="fixed_request_params"
                  v-model="company_news_config.fixed_request_params"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Parámetro inicio paginación:"
                  type="number"
                  v-model="company_news_config.pagination_start_param"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  type="number"
                  label="Parámetro inicio paginación:"
                  v-model="company_news_config.pagination_limit_param"
                />
              </CCol>
            </CRow>

            <div style="display: flex; align-items: center">
              <label style="margin: 0; margin-right: 15px"
                >Login activado:
              </label>
              <CSwitch
                variant="opposite"
                color="dark"
                :checked.sync="login_activo"
                shape="pill"
              />
            </div>

            <div v-if="login_activo">
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Usuario login:"
                    type="text"
                    v-model="company_news_config.login_username"
                  />
                  <small v-if="!company_news_config.login_username">*Campo Requerido</small>
                </CCol>
                <CCol sm="6" style="position:relative;">
                  <CInput
                    label="Contraseña login:"
                    :type="togglePass ? 'password' : 'text'"
                    v-model="company_news_config.login_password"
                  />
                  <img @click="togglePass = !togglePass" style="cursor: pointer; width: 18px; position: absolute;left: 92%; transform: translate(-50%, 0); top: 44%;" src="../../assets/icons/svg/eye-solid.svg" alt="" srcset="">
                  <small v-if="!company_news_config.login_password">*Campo Requerido</small>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="URL login:"
                    type="text"
                    v-model="company_news_config.login_url"
                  />
                  <small v-if="!company_news_config.login_url">*Campo Requerido</small>
                </CCol>
                <CCol sm="6">
                  <CSelect
                  placeholder="Selecciona una opción"
                    style="width: 200px; margin-top: 12px"
                    label="Tipo de Petición login:"
                    :options="[
                      { value: 'get', label: 'GET' },
                      { value: 'post', label: 'POST' },
                    ]"
                    v-model="company_news_config.login_request_type"
                  />
                  <small v-if="!company_news_config.login_request_type">*Campo Requerido</small>
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
        <button style="margin-top:12px;" :disabled="externalFormDisabled" class="saveBtn" @click="saveExternalOptions">Guardar</button>
        <div class="alert" style="display: inline-block; padding-bottom:0;">
          <CAlert v-if="alertSuccess2" color="success">Configuración actualizada</CAlert>
        </div>
        <div class="alert" style="display: inline-block; padding-bottom:0;">
          <CAlert v-if="alertError2" color="red">Error al acutalizar</CAlert>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCompanyNews,
  deleteLocalNews,
  createLocalNews,
  updateLocalNews,
  getLocalNewsConfigOptions,
  changeNewsConfigOptions,
  changeNewsConfigExternalOptions,
} from "../../api/companyConfig.js";
import { CDataTable, CModal, CFormCheck } from "@coreui/vue";
import moment from "moment";

export default {
  components: {
    CDataTable,
    CModal,
  },
  data() {
    return {
      togglePass: false,
      currentNew: {},
      newNew: {
        title: "",
        date: "",
        url: "",
        image: "",
        body: "",
/*         notifications: true, */
      },
      newsConfig: [],
      //modales
      createModal: false,
      editModal: false,
      deleteModal: false,
      //tabla
      start: 1,
      limit: 10,
      totalNews: 0,
      news: [],
      fields: [
        {
          key: "title",
          label: "Título",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
      //Imagenes
      fileNameImageCreate: "",
      previewImageCreate: "",
      fileNameImageUpdate: "",
      previewImageUpdate: "",
      fileNameMain_icon: "",
      previewMain_icon: "",

      //crud configuracion general
      company_news_app_data: {},

      company_news_config: {},
      login_activo: null,

      alertSuccess1: false,
      alertError1: false,
      alertSuccess2: false,
      alertError2: false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalNews / this.limit);
    },
    externalFormDisabled() {
      if ((this.login_activo && (!this.company_news_config.login_username ||
        !this.company_news_config.login_password ||
        !this.company_news_config.login_url ||
        !this.company_news_config.login_request_type)) || (!this.company_news_config.request_type || !this.company_news_config.news_request_url)) {
        return true
      } else {
        return false
      }

    }
  },
  methods: {
    getNews() {
      const data = { start: this.start, limit: this.limit };
      getCompanyNews(data).then((res) => {
        this.news = res.news;
        this.totalNews = res.total;
      });
    },
    setCurrentItem(item, modal) {
      this.currentNew = { ...item };

      this.currentNew.date = moment(item.date).add(1, 'days').format("YYYY-MM-DD");

      //qué modal se abre
      modal == "edit"
        ? (this.editModal = !this.editModal)
        : (this.deleteModal = !this.deleteModal);

      //preview imágenes
      this.previewImageUpdate = this.currentNew.image
        ? this.currentNew.image
        : "";
    },
    onChangeImageCreate(e) {
      this.newNew.image = e.target.files["0"];
      this.previewImageCreate = URL.createObjectURL(e.target.files["0"]);
      this.fileNameImageCreate = e.target.files["0"].name;
    },
    onChangeImageUpdate(e) {
      this.currentNew.image = e.target.files["0"];
      this.previewImageUpdate = URL.createObjectURL(e.target.files["0"]);
      this.fileNameImageUpdate = e.target.files["0"].name;
    },
    onChangeMain_icon(e) {
      this.company_news_app_data.main_icon = e.target.files["0"];
      this.previewMain_icon = URL.createObjectURL(e.target.files["0"]);
      this.fileNameMain_icon = e.target.files["0"].name;
    },
    deleteNew() {
      deleteLocalNews(this.currentNew.id).then((res) => {
        this.deleteModal = !this.deleteModal;
        this.getNews();
      });
    },
    createNew() {
      const data = new FormData();
      const obj = {...this.newNew}
      obj.notifications = this.newNew.notifications == true ? 1 : 0;
      for (const property in obj) {
        data.append(property, obj[property]);
      }
      createLocalNews(data).then((res) => {
        this.createModal = !this.createModal;
        this.getNews();
        this.newNew = {
        title: "",
        date: "",
        url: "",
        image: "",
        body: "",
        notifications: true,
      };
      this.previewImageCreate = '';
      this.fileNameImageCreate = ''; 
      });
    },
    updateNew() {
      const data = new FormData();

      const originalObj = this.news.filter(
        (n) => n.id == this.currentNew.id
      )[0];
      for (const property in this.currentNew) {
        if (originalObj[property] !== this.currentNew[property]) {
          data.append(property, this.currentNew[property]);
        }
      }
      data.append("_method", "PATCH");
      data.append("news_id", this.currentNew.id);
      updateLocalNews(data).then((res) => {
        this.editModal = !this.editModal;
        this.getNews();
      });
    },
    getLocalNewsConfig() {
      getLocalNewsConfigOptions().then((res) => {
        this.company_news_app_data = res.company_news_app_data ? res.company_news_app_data : {};
        this.original_company_news_app_data = res.company_news_app_data ? res.company_news_app_data : {};
        this.company_news_config = res.company_news_config ? res.company_news_config : {};

        this.previewMain_icon = res.company_news_app_data.main_icon;


        //estado inicial login_activo
        this.login_activo = !res.company_news_config?.login_username &&
        !res.company_news_config?.login_password &&
        !res.company_news_config?.login_url &&
        !res.company_news_config?.login_request_type
        ?
        false
        :
        true
      });
    },

    saveConfigOptions() {
      const obj = { ...this.company_news_app_data };
      obj.active = true ? 1 : 0;
      obj.notifications = true ? 1 : 0;
      const data = new FormData();

      if (!obj.active) {
        data.append("active", obj.active);
      } else {
        for (const property in obj) {
          data.append(property, obj[property]);
        }
        if (typeof obj.main_icon == "string") {
          data.delete("main_icon");
        }
      }

      data.append("_method", "PATCH");
      changeNewsConfigOptions(data).then((res) => {
        this.alertSuccess1 = true;
        setTimeout(() => {
          this.alertSuccess1 = false;
        }, 1000);
      }).catch((e) => {
        this.alertError1 = true;
        setTimeout(() => {
          this.alertError1 = false;
        }, 1000);
        });
    },
    saveExternalOptions() {
      const data = {...this.company_news_config};
      if (!this.login_activo) {
        delete data.login_username
        delete data.login_password
        delete data.login_url
        delete data.login_request_type
      }

      changeNewsConfigExternalOptions(data).then((res) => {
        this.alertSuccess2 = true;
        setTimeout(() => {
          this.alertSuccess2 = false;
        }, 1000);
      }).catch((e) => {
        this.alertError2 = true;
        setTimeout(() => {
          this.alertError2 = false;
        }, 1000);
        });
    },
    updateActiveNewsConfig(active) {
      const data = new FormData();
      data.append("active", active);
      data.append("_method", "PATCH");
      changeNewsConfigOptions(data).then((res) => {
        console.log(res);
    })
    },
  },
  watch: {
    start() {
      this.getNews();
    },
    //active config noticias
    'company_news_app_data.active': function (active){
      const activeNumber = active == true ? 1 : 0;
         this.updateActiveNewsConfig(activeNumber)
     },
      //active noticias externas --> seclect local o externas
    'company_news_config.active': function (active){
      const activeNumber = active == true ? 1 : 0;
         changeNewsConfigExternalOptions({active: activeNumber})
     },
  },
  mounted() {
    this.getNews();
    this.getLocalNewsConfig();
  },
};
</script>

<style lang="scss" scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
@import '../../assets/scss/style.scss';
.file-input-group {
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.color-input {
  display: flex;
  align-items: center;
  gap: 20px;
}
.color-input label {
  margin-bottom: 0;
}
.img-preview {
  margin-top: 8px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.label-img {
  margin-right: 5px;
}
.saveBtn {
  background: transparent linear-gradient(180deg, $gradient-color-1 0%, $gradient-color-2 100%) 0%
    0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  top: 410px;
  left: 339px;
  width: 123px;
  height: 37px;
  font: normal normal normal 14px/18px Montserrat;
  color: #fff;
  border: none;
}

.saveBtn:disabled,
.saveBtn[disabled]{
  opacity: .6;
}

.form-group {
  margin-bottom: 8px;
  margin-top: 0 !important;
}
</style>
